<template>
  <div>
    <div>
      <div style="display: flex">
        <el-select placeholder="机构" v-model="params.orgCode" filterable :clearable="false" style="width: 260px"
          @change="orgChange(params.orgCode)">
          <el-option v-for="item in orgList" :key="item.orgCode" :label="item.orgName" :value="item.orgCode">
          </el-option>
        </el-select>
        <el-select v-model="params.schedulingPeriodId" clearable @change="schedulingPeriodChange" placeholder="班段"
          style="margin-left: 10px">
          <el-option v-for="(spl, i) in schedulingPeriodList" :key="spl.id" :label="spl.name" :value="spl.id">
          </el-option>
        </el-select>
        <el-date-picker  v-model="week" :clearable="false" type="week" format="yyyy 第 WW 周" value-format="yyyy-MM-dd" :picker-options="{firstDayOfWeek: 1,}" @change="changeWeek()" placeholder="选择周" style="margin-left: 10px"></el-date-picker>
        <el-button-group style="margin-left: 10px">
          <el-button type="primary" @click="lastWeek">上周</el-button>
          <el-button type="primary" @click="nextWeek">下周</el-button>
          <el-button type="primary" @click="getByDateRange">查询</el-button>
        </el-button-group>
        <el-button-group style="margin-left: 20px">
            <el-button v-btn-permission="'008007002002'" type="primary" @click="duplicateScheduledialogVisible = true" :disabled="!compareDate2()">复制排班</el-button>
            <el-button v-btn-permission="'008007002003'" type="primary" @click="defaultdialogVisible = true">默认排班</el-button>
        </el-button-group>
        <el-button-group style="margin-left: 20px">
          <div v-btn-permission="'008007002004'">
            <el-button type="primary" @click="exportListData">导出</el-button>
          </div>
        </el-button-group>
      </div>
      <el-calendar :range="calendarRange">
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template slot="dateCell" slot-scope="{ date, data }">
          <div style="display: flex; justify-content: space-between">
            <div>{{ data.day.split("-").slice(1).join("-") }}</div>
            <el-button size="mini" type="primary" v-if="compareDate(data.day)" v-btn-permission="'008007002001'" @click="
              openScheduling(
                getObjByOp(
                  schedulingCalendarList,
                  'schedulingDate',
                  data.day
                ),
                data.day
              )
            ">编辑</el-button>
          </div>
          <div v-if="
            getObjByOp(schedulingCalendarList, 'schedulingDate', data.day)
          ">
            <div v-for="(spl, i) in getObjByOp(
              schedulingCalendarList,
              'schedulingDate',
              data.day
            ).schedulingPeriodList" :key="i">
              <el-card body-style="padding: 10px" shadow="hover" style="margin: 5px 0">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                  ">
                  <span :style="{ background: spl.color }"
                    style="color: #f6f6f6; border-radius: 5px; padding: 2px 5px">{{ spl.abbreviationName }}</span>
                </div>
                <div style="margin-left: 5px">
                  <el-tag :type="
                    spl.startTimeIsCrossDay === '1' ? 'warning' : 'success'
                  " size="mini" effect="dark">{{
    spl.startTimeIsCrossDay
    | dictFilter($store.state.dict.isCrossDay)
}}</el-tag>
                  {{ spl.startTime }}
                  -
                  <el-tag :type="
                    spl.endTimeIsCrossDay === '1' ? 'warning' : 'success'
                  " size="mini" effect="dark">{{
    spl.endTimeIsCrossDay
    | dictFilter($store.state.dict.isCrossDay)
}}</el-tag>
                  {{ spl.endTime }}
                </div>
                <div>
                  <el-tooltip v-for="(spsl, ind) in spl.schedulingPersonList" :key="ind" :content="'电话：' + spsl.phone"
                    placement="bottom">
                    <el-tag style="margin: 5px" size="small">{{
                        spsl.doctorName
                    }}</el-tag>
                  </el-tooltip>
                </div>
              </el-card>
            </div>
          </div>
          <div v-else>无排班</div>
        </template>
      </el-calendar>
    </div>
    <div>
      <el-dialog title="添加排班" :visible.sync="schedulingDialog" width="50%">
        <div>
          <!--          {{schedulingCalendar}}-->
          <!-- <div v-if="
            schedulingCalendar.schedulingPeriodList &&
            schedulingCalendar.schedulingPeriodList.length > 0
          "> -->
          <div>
            <el-table :data="bdtableData" border style="width: 100%">
              <el-table-column prop="name" label="班段" width="180">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.name }} ({{ scope.row.startTime }}-{{ scope.row.endTime
                  }})</span>

                </template>
              </el-table-column>
              <el-table-column prop="" label="值班人员" width="">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.schedulingPersonList" style=" width: 100%" multiple value-key="doctorId"
                    placeholder="选择值班人" filterable>
                    <el-option v-for="doctor in doctorList" :key="doctor.doctorId" :label="doctor.doctorName"
                      :value="doctor"></el-option>
                  </el-select>
                </template>
              </el-table-column>

            </el-table>
            <!-- <div style="min-height: 98px">
              <div v-for="(
                  schedulingPeriod, i
                ) in schedulingCalendar.schedulingPeriodList" :key="i">
                <div style="
                    display: flex;
                    justify-content: space-between;
                    margin: 5px 20px;
                    align-items: center;
                  ">

                  <el-select v-model="schedulingPeriod.id" @change="editSchedulingPeriodChange(schedulingPeriod)"
                    placeholder="请选择班次">
                    <el-option v-for="(spl, i) in schedulingPeriodList" :key="spl.id" :label="spl.name" :value="spl.id">
                    </el-option>
                  </el-select>
                  <span style="padding: 0 10px">-</span>
                  <el-select v-model="schedulingPeriod.schedulingPersonList"
                    @change="editSchedulingPersonChange(schedulingPeriod)" style="width: 100%" multiple
                    value-key="doctorId" placeholder="选择值班人" filterable>
                    <el-option v-for="doctor in doctorList" :key="doctor.doctorId" :label="doctor.doctorName"
                      :value="doctor"></el-option>
                  </el-select>
                  <el-button type="danger" style="margin-left: 10px" icon="el-icon-delete" circle size="mini" @click="
                    schedulingCalendar.schedulingPeriodList.removeByIndex(i)
                  "></el-button>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center; margin: 0 20px 0">
              <el-button type="success" style="width: 100%" size="mini" @click="
                schedulingPeriod(schedulingCalendar.schedulingPeriodList)
              " plain icon="el-icon-plus"></el-button>
            </div> -->
          </div>
          <!-- <div v-else @click="schedulingPeriod(schedulingCalendar.schedulingPeriodList)" class="no-scheduling">
            暂无排班，点击新增
          </div> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="qxpppp">取 消</el-button>
          <el-button type="primary" @click="addScheduling()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 复制排班 -->
    <el-dialog title="复制排班" :visible.sync="duplicateScheduledialogVisible" width="30%"
      :before-close="handleClose_duplicateScheduledialogVisible">
      <div style="color:red;margin-bottom:10px">注：点击确定后将覆盖已排的全部排班信息</div>
      <el-date-picker v-model="copeweek" :clearable="false" type="week" format="yyyy 第 WW 周" value-format="yyyy-MM-dd" :picker-options="pickerOptionsWeek" @change="changeWeek_cope()" placeholder="选择周" style="margin-left: 10px">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="duplicateScheduledialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="duplicateScheduleSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 默认排班 -->
    <el-dialog title="默认排班" :visible.sync="defaultdialogVisible" width="30%" :before-close="handleClose_defaultweek">
      <div style="color:red;margin-bottom:10px">注：自动生成排班将以每周日0点自动生成，如果有自行排班将会被覆盖。</div>
      <el-date-picker v-model="defaultweek" :clearable="true" type="week" format="yyyy 第 WW 周"
        value-format="yyyy-MM-dd" :picker-options="{
          firstDayOfWeek: 1,
        }" @change="changeWeek_default()" placeholder="选择周" style="margin-left: 10px">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="defaultdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="defaultSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _debounce } from '@/utils/utils.js'
import {
  addScheduling,
  getByDateRange,
  getByDatePeriod
} from "../../api/schedulingperiod/scheduling";
import { getSchedulingPeriodList, copyScheduling, saveDefaultSchedulingWeek,exportScheduling } from "../../api/schedulingperiod/schedulingperiod";
import { getUserListByPeopleTypeApi,getBusinessOrgList} from "@/api/SystemManagement/index";
import moment from "moment";
import { messageBox, importFile } from '../../utils/utils';
import M from "minimatch";

export default {
  name: "scheduling",
  data() {
    return {
      params: {
        startDate: "",
        endDate: "",
        schedulingType: "community",
      },
      bdtableData: [],
      schedulingCalendar: {
        schedulingDate: "",
        hospitalId: "",
        orgCode: "",
        orgName: "",
        schedulingType: "community",
        schedulingPeriodList: [],
      },
      schedulingPeriodList: [],
      week: "",
      orgList: [],
      doctorList: [],
      schedulingCalendarList: [],
      calendarRange: [],
      copyzhou:[],
      morenzhou:[],
      schedulingDialog: false,
      duplicateScheduledialogVisible:false,
      defaultdialogVisible:false,
      copeweek:'',
      defaultweek:'',
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      // 周时间禁用
      pickerOptionsWeek: {
        disabledDate(time) {
          // console.log(time);
          let millisecond = 0;
          switch (new Date().getDay()) {
            case 0:
              millisecond = 8.64e7;
              break;
            case 1:
              millisecond = 24 * 60 * 60 * 1000 * 2;
              break;
            case 2:
              millisecond = 24 * 60 * 60 * 1000 * 3;
              break;
            case 3:
              millisecond = 24 * 60 * 60 * 1000 * 4;
              break;
            case 4:
              millisecond = 24 * 60 * 60 * 1000 * 5;
              break;
            case 5:
              millisecond = 24 * 60 * 60 * 1000 * 6;
              break;
            case 6:
              millisecond = 24 * 60 * 60 * 1000 * 7;
              break;
            default:
              break;
          }
          return !(time.getTime() < Date.now() - millisecond);
        },
      },
    };
  },
  created() {
    this.setCalendarRangeToCurrentWeek1();
  },
  mounted() {
    console.log(this.week);
    // this.orgQueryList(); //获取数据
    this.getBusinessOrgList()
    // this.getSchedulingPeriodList();
    // this.querySchedulingPeriodById()
    console.log(this.$route);
    console.log(this.schedulingCalendar);
  },
  methods: {
    // 获取组织架构列表
    // 获取机构 get 请求
    async getBusinessOrgList() {
      //加载机构居家隔离业务类型为是的
      let params = { 
        homeIsolation: 1,
        transshipmentTask:1,
        orgCode:this.userdoctor.orgCode
      }
      const { data } = await getBusinessOrgList({params});
      console.log(data);
      this.orgList = data.data;
      this.params.orgCode = data.data[0].orgCode;
      let item = data.data[0]
      this.getSchedulingPeriodList(item)
      this.getByDateRange();
    },
    // orgQueryList() {
    //   let obj = {
    //     // hospitalId:"486790865826611200",
    //     // orgCode: "001062001",
    //     transshipmentTask:1,
    //     homeIsolation:1
    //   }
    //   console.log(obj);
    //   getBusinessOrgList({obj}).then(res=>{
    //     console.log('res',res);
    //   })
    //   getAllMIOrgList().then((res) => {
    //     console.log(res,'55555');
    //     this.orgList = res.data.data;
    //     this.params.orgCode = res.data.data[0].orgCode;
    //     this.getByDateRange();
    //   });
    // },
    getObjByOp(obj, key, val) {
      let object = null;
      obj.forEach((o) => {
        if (o[key] === val) {
          object = o;
        }
      });
      return object;
    },
    openScheduling(val, day) {
      // console.log(val);
      // // if (val) {
          let obj = {
          responsibleOrgCode:this.params.orgCode,
          schedulingDate:day,
          schedulingPeriodList:this.bdtableData,
          schedulingType:this.params.schedulingType
        }
        console.log(obj);
        getByDatePeriod(obj).then(res=>{
          console.log(res,9999999999999999);
          this.bdtableData = res.data.data
          
        })
        // console.log(val.schedulingPeriodList,111111111111);
        console.log(this.bdtableData);
      //   let newArr = []
      //   // this.bdtableData = val.schedulingPeriodList
      //   this.bdtableData.forEach(item=>{
      //     // console.log(item,1111);
      //     val.schedulingPeriodList.forEach(data=>{
      //       // console.log(data.name,2222);
      //       if(item.name == data.name){
      //         // console.log('我执行了');
      //         item = data
      //         console.log(item);
      //       }else{
      //         Vue.set(item, 'schedulingPersonList', [])
      //       }
      //     })
      //     newArr.push(item)
      //     // console.log(item);
      //   })
      //   console.log(newArr);
      //   this.bdtableData = newArr
      // }else{
      //   console.log(this.bdtableData);
      //   this.bdtableData.forEach(item=>{
      //     item.schedulingPersonList=[]
      //   })
      // }
      
      // console.log(val.schedulingPeriodList);
      // console.log(9999);
      this.getUserListByPeopleType();
      this.schedulingCalendar = JSON.parse(JSON.stringify(val)) || {
        schedulingDate: day,
        hospitalId: "",
        orgCode: "",
        orgName: "",
        schedulingType:this.params.schedulingType,
        schedulingPeriodList: [],
      };
      this.schedulingCalendar.hospitalId = this.$store.state.app.hospitalId;
      this.schedulingCalendar.orgCode = this.orgList.getByKey(
        "orgCode",
        this.params.orgCode
      ).orgCode;
      this.schedulingCalendar.orgName = this.orgList.getByKey(
        "orgCode",
        this.params.orgCode
      ).orgName;
      this.schedulingCalendar.schedulingType = this.params.schedulingType;
      this.schedulingDialog = true;
    },
    // 获取机构
    getUserListByPeopleType() {
      getUserListByPeopleTypeApi({
        params: {
          orgCode: this.params.orgCode,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.doctorList = [];
          res.data.data.forEach((item) => {
            this.doctorList.push({
              doctorId: item.id,
              doctorName: item.name,
              phone: item.linkPhone,
            });
          });
        }
        this.$forceUpdate();
      });
    },
    // 社区切换
    orgChange(v) {
      // console.log(v);
      let item = {}
      this.orgList.forEach(res=>{
        if(res.orgCode === v){
          item = res
        }
      })
      // console.log(item);
      this.getSchedulingPeriodList(item)
      
      this.$forceUpdate();
      this.getByDateRange();
      this.pbddd(v)  //选择社区加载对应的排班

    },
    // 社区切换
    schedulingPeriodChange() {
      this.$forceUpdate();
      this.getByDateRange();
    },
    getByDateRange() {
      this.params.startDate = this.calendarRange[0];
      this.params.endDate = this.calendarRange[1];
      getByDateRange({ params: this.params }).then((res) => {
        if (res.data.code === "200") {
          this.schedulingCalendarList = res.data.data;
          console.log(this.schedulingCalendarList);
          // if (this.schedulingCalendarList.length === 0) {
          //   this.$message({
          //     message: '没有排班信息',
          //     type: 'warning'
          //   })
          // }
        }
      });
    },
    // 安排日程
    addScheduling:_debounce(function() {
      console.log(888);
      // if (this.schedulingCalendar.schedulingPeriodList && this.schedulingCalendar.schedulingPeriodList.length === 0) {
      //   this.schedulingDialog = false
      //   return
      // }
      this.schedulingCalendar.hospitalId = this.$store.state.app.hospitalId;
      this.schedulingCalendar.schedulingPeriodList = this.bdtableData

      addScheduling(this.schedulingCalendar).then((res) => {
        if (res.data.code === "200") {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.schedulingDialog = false;
          this.getByDateRange();
        }
      });
    },300),
    // 获取排班周期列表
    //post请求
    getSchedulingPeriodList(data) {
      console.log(data);
      // let params = {
      //   "businessTypes": [1,3],
      //   "hospitalId": id
      // }
      let params = {}
      if (data.homeIsolation == 1&&data.transshipmentTask == 1) {
        params = {
          "businessTypes": [1,3],
          "hospitalId": data.hospitalId
        }
      }else if(data.homeIsolation == 1 || data.transshipmentTask != 1){
        params = {
          "businessTypes": [1],
          "hospitalId": data.hospitalId
        }
      }else if(data.homeIsolation != 1 || data.transshipmentTask == 1){
        params = {
          "businessTypes": [3],
          "hospitalId": data.hospitalId
        }
      }
      console.log(params);
      getSchedulingPeriodList(params).then(res => {
          console.log(res);
            this.schedulingPeriodList = res.data.data;
            this.bdtableData = res.data.data;
      })

      // getSchedulingPeriodList({
      //   // types: "universal,community",
      //   businessTypes: [1, 2, 3]
      // }).then((res) => {
      //   this.schedulingPeriodList = res.data.data;
      //   this.bdtableData = res.data.data;
      // });
    },
    changeWeek() {
      // 根据日期获取当前周的日期
      this.setCalendarRangeToCurrentWeek(this.week,'week');
      this.getByDateRange();
    },
    changeWeek_cope() {
      this.setCalendarRangeToCurrentWeek(this.copeweek,'copeweek');
    },
    changeWeek_default() {
      console.log(4445,this.defaultweek); //null
      this.setCalendarRangeToCurrentWeek(this.defaultweek,'defaultweek');
    },
    // 设置calendarRange为当前周
    setCalendarRangeToCurrentWeek1(obj) {
      let now = obj ? new Date(obj) : new Date();
      let nowTime = now.getTime();
      let day = now.getDay();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let MondayTime = "";
      let SundayTime = "";

      if (day === 0) {
        // 如果是周日
        MondayTime = moment(nowTime - 6 * oneDayTime).format("YYYY-MM-DD"); // 显示周一
        SundayTime = moment(nowTime).format("YYYY-MM-DD"); // 显示周日
      } else if (day === 1) {
        // 如果是周一
        MondayTime = moment(nowTime).format("YYYY-MM-DD"); // 显示周一
        SundayTime = moment(nowTime + 6 * oneDayTime).format("YYYY-MM-DD"); // 显示周日
      } else {
        MondayTime = moment(nowTime - (day - 1) * oneDayTime).format(
          "YYYY-MM-DD"
        ); // 显示周一
        SundayTime = moment(nowTime + (7 - day) * oneDayTime).format(
          "YYYY-MM-DD"
        ); // 显示周日
      }
      this.calendarRange = [MondayTime, SundayTime];
      this.week = MondayTime;
    },
    setCalendarRangeToCurrentWeek(obj,flag) {
      let now = obj ? new Date(obj) : obj === null? '':new Date();
      if(obj!==null){
        let nowTime = now.getTime();
        let day = now.getDay();
        let oneDayTime = 24 * 60 * 60 * 1000;
        let MondayTime = "";
        let SundayTime = "";

        if (day === 0) {
          // 如果是周日
          MondayTime = moment(nowTime - 6 * oneDayTime).format("YYYY-MM-DD"); // 显示周一
          SundayTime = moment(nowTime).format("YYYY-MM-DD"); // 显示周日
        } else if (day === 1) {
          // 如果是周一
          MondayTime = moment(nowTime).format("YYYY-MM-DD"); // 显示周一
          SundayTime = moment(nowTime + 6 * oneDayTime).format("YYYY-MM-DD"); // 显示周日
        } else {
          MondayTime = moment(nowTime - (day - 1) * oneDayTime).format(
            "YYYY-MM-DD"
          ); // 显示周一
          SundayTime = moment(nowTime + (7 - day) * oneDayTime).format(
            "YYYY-MM-DD"
          ); // 显示周日
        }
        if(flag === 'week'){
        this.week = MondayTime;
        this.calendarRange = [MondayTime, SundayTime];
        }else if(flag === 'copeweek'){
          this.copeweek = MondayTime
          this.copyzhou = [MondayTime, SundayTime]
        }else if(flag === 'defaultweek'){
          this.defaultweek = MondayTime
          this.morenzhou = [MondayTime, SundayTime]
          console.log(this.morenzhou);
        }
      }else{
        let MondayTime = "";
        let SundayTime = "";
        if(flag === 'week'){
        this.week = MondayTime;
        this.calendarRange = [MondayTime, SundayTime];
        }else if(flag === 'copeweek'){
          this.copeweek = MondayTime
          this.copyzhou = [MondayTime, SundayTime]
        }else if(flag === 'defaultweek'){
          this.defaultweek = MondayTime
          this.morenzhou = [MondayTime, SundayTime]
          // console.log(this.morenzhou);
        }
      }
      
      // this.calendarRange = [MondayTime, SundayTime];
      // console.log("🚀 ~ file: communityScheduling.vue ~ line 379 ~ setCalendarRangeToCurrentWeek ~  this.calendarRange ", this.calendarRange)
      
    },
    // 获取上一周
    lastWeek() {
      this.calendarRange = [
        moment(this.calendarRange[0]).subtract(7, "days").format("YYYY-MM-DD"),
        moment(this.calendarRange[1]).subtract(7, "days").format("YYYY-MM-DD"),
      ];
      this.week = this.calendarRange[0];
      this.getByDateRange();
    },
    // 获取下一周
    nextWeek() {
      this.calendarRange = [
        moment(this.calendarRange[0]).add(7, "days").format("YYYY-MM-DD"),
        moment(this.calendarRange[1]).add(7, "days").format("YYYY-MM-DD"),
      ];
      this.week = this.calendarRange[0];
      this.getByDateRange();
    },
    // schedulingPeriod(val) {
    //   val.push({});
    //   console.log(val);
    // },
    editSchedulingPeriodChange(val) {
      let schedulingPeriod = this.schedulingPeriodList.getByKey("id", val.id);
      val.name = schedulingPeriod.name;
      val.abbreviationName = schedulingPeriod.abbreviationName;
      val.color = schedulingPeriod.color;
      val.startTime = schedulingPeriod.startTime;
      val.startTimeIsCrossDay = schedulingPeriod.startTimeIsCrossDay;
      val.endTime = schedulingPeriod.endTime;
      val.endTimeIsCrossDay = schedulingPeriod.endTimeIsCrossDay;
      val.type = schedulingPeriod.type;
      val.state = schedulingPeriod.state;
    },
    editSchedulingPersonChange(val) {
      for (let i = 0; i < val.schedulingPersonList.length; i++) {
        val.schedulingPersonList[i].sort = i + 1;
      }
      // val.schedulingPersonList.forEach((item, i) => {
      //   val.schedulingPersonList.sort = i + 1
      // })
    },
    // 比较两个日期的大小
    compareDate(date) {
      let oDate1 = new Date(date);
      let oDate2 = new Date(moment(new Date().getTime()).format("YYYY-MM-DD"));
      return oDate1.getTime() >= oDate2.getTime();
    },
    compareDate2() {
      let now = this.week ? new Date(this.week) : new Date()
      let day = now.getDay();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let MondayTime = "";
      let nowTime = now.getTime();
      MondayTime = moment(nowTime - (day - 1) * oneDayTime).format(
          "YYYY-MM-DD"
      )
        // console.log( MondayTime );
      let oDate1 = new Date(MondayTime); //周一
      let oDate2 = new Date(moment(new Date().getTime()).format("YYYY-MM-DD")); //当天
      // console.log(oDate1.getTime() <= oDate2.getTime());
      return oDate1.getTime() >= oDate2.getTime();
    },
    // 复制排班确定
    async duplicateScheduleSure() {
      let params = {
        startDate: this.copyzhou[0],
        endDate: this.copyzhou[1],
        schedulingType: this.params.schedulingType,
        schedulingOrgCode:this.params.orgCode
      }
      console.log(params);
      try {
        console.log(1);
        let { data } = await copyScheduling(params)
        if (data.code == 200) {
          console.log(data);
          this.$message({
            message:data.message,
            type: 'success'
          })
        }else{
        // this.$message.error(data.message)
        }
      } catch (error) {
        this.$message.error(error)
      }
      this.duplicateScheduledialogVisible = false
    },
    // 默认排班确定
    async defaultSure() {
      let params = {
        startDate: this.morenzhou[0],
        endDate: this.morenzhou[1],
        schedulingType: this.params.schedulingType,
        schedulingOrgCode:this.params.orgCode
      }
      try {
        let { data } = await saveDefaultSchedulingWeek(params)
        if (data.code == 200) {
          console.log(data);
          this.$message({
            message:data.message,
            type: 'success'
          })
        }
      } catch (error) {
        this.$message.error(error)
      }
      this.defaultdialogVisible = false
    },
    handleClose_duplicateScheduledialogVisible() {
      this.duplicateScheduledialogVisible = false
    },
    handleClose_defaultweek() {
      this.defaultdialogVisible = false
    },
    // 导出列表数据
    exportListData() {
      messageBox(() => {
        this.fullscreenLoading = true;
        exportScheduling(this.params).then(res => {
          this.fullscreenLoading = false;
          importFile(res.data, '排班列表数据')
        })
      }, "warning", '确定导出表格中的数据吗？')
    },
    qxpppp() {
      console.log(this.bdtableData);
      // this.bdtableData.schedulingPeriodList = []
      // this.bdtableData = this.schedulingPeriodList

      this.schedulingDialog = false
    },
    pbddd(v) {
      let n, nn;
      console.log('vv', v);
      for (let i in this.orgList) {
        if (this.orgList[i].orgCode == v) {
          n = this.orgList[i].orgType
        }
      }
      // 如果是隔离就显示隔离
      if (n == 4) {
        nn = [2]
      } else {
        nn = [1, 3]
      }
      getSchedulingPeriodList({
        businessTypes: nn
      }).then((res) => {
        this.schedulingPeriodList = res.data.data;
        this.bdtableData = res.data.data;
        console.log(this.bdtableData);
      });

    }
  },
};
</script>

<style scoped>
.is-selected {
  color: #1989fa;
}

/deep/ .el-calendar-table .el-calendar-day {
  min-height: 85px !important;
  height: auto !important;
  transition: background-color 0.2s ease;
}

/deep/ .el-calendar-table .current:hover {
  cursor: pointer !important;
  background-color: #f2f8fe !important;
}

.no-scheduling {
  color: #999999;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 50px 0;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 8px;
}

.no-scheduling:hover {
  box-shadow: #d3d3d3 0px 0px 10px;
}
</style>
